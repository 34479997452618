<template>
  <div>
    <b-tabs
      pills
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-class="nav-left"
      nav-wrapper-class="col-md-3 col-12"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <booking-order-detail-general
          :general="bookings"
        ></booking-order-detail-general>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BoxIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Propiedad</span>
        </template>
        <booking-order-detail-property
          :property="bookings.bookingrequest.property"
        ></booking-order-detail-property>
      </b-tab>
      <!--
            <b-tab v-if="bookings.bookingrequest.quotation.propertyquotation[0].warehouse != null">
                <template #title>
                <feather-icon
                    icon="BoxIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">Bodega</span>
                </template>
                <booking-order-detail-warehouse :warehouse="bookings.bookingrequest.quotation.propertyquotation[0].warehouse"></booking-order-detail-warehouse>
            </b-tab>
            -->
      <!--
      <b-tab
        v-if="
          bookings.bookingrequest.quotation.propertyquotation[0].parkinglot !=
          null
        "
      >
        <template #title>
          <feather-icon icon="TruckIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Estacionamiento</span>
        </template>
        <booking-order-detail-parkinglot
          :parkinglot="
            bookings.bookingrequest.quotation.propertyquotation[0].parkinglot
          "
        ></booking-order-detail-parkinglot>
      </b-tab>
      -->
      <b-tab v-if="bookings.bookingrequest.payments.length > 0">
        <template #title>
          <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Pago</span>
        </template>
        <booking-order-detail-payment
          :payments="bookings.bookingrequest.payments"
        ></booking-order-detail-payment>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BookingOrderDetailGeneral from './details/order/BookingOrderDetailGeneral'
import BookingOrderDetailProperty from './details/order/BookingOrderDetailProperty'
import BookingOrderDetailParkinglot from './details/order/BookingOrderDetailParkinglot'
import BookingOrderDetailWarehouse from './details/order/BookingOrderDetailWarehouse'
import BookingOrderDetailPayment from './details/order/BookingOrderDetailPayment'

export default {
  name: 'BookingOrderDetailList',
  props: ['bookings'],
  components: {
    BookingOrderDetailGeneral,
    BookingOrderDetailProperty,
    BookingOrderDetailParkinglot,
    BookingOrderDetailWarehouse,
    BookingOrderDetailPayment
  },
  data () {
    return {}
  }
}
</script>
