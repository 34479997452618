<template>
  <div id="bookings-details">
    <bookings-orders-details-list :bookings="bookings"></bookings-orders-details-list>
  </div>
</template>

<script>
  import BookingsOrdersDetailsList from './components/BookingsOrdersDetailsList'

  export default{
    name: 'BookingsDetails',
    components: {BookingsOrdersDetailsList},
    props: ['bookings'],
    data() {
      return {
      }
    },
  }
</script>
